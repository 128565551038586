import { DiscountType } from "../types";
import styles from "./style.module.css";
import { Radio } from "antd";

export function SelectDiscountType({
  applyRange,
  applyRangeChange,
}: {
  applyRange: DiscountType;
  applyRangeChange: (value: DiscountType) => void;
}) {
  return (
    <div className={styles.content}>
      <Radio.Group
        value={applyRange}
        onChange={(e) => {
          applyRangeChange(e.target.value);
        }}
      >
        <Radio value="all">活动折扣</Radio>
        <Radio value="exclusive">专属折扣</Radio>
      </Radio.Group>
    </div>
  );
}
