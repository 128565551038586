import { Input, message, Modal } from "antd";
import styles from "./style.module.css";
import { ProductDiscountForm } from "../types";
import { useCallback, useEffect, useState } from "react";
import { createDiscount, getProductSummary } from "@/api/pricing";
import { realPriceFormat, removeObjectEmptyValues } from "@/utils";
import { SelectDiscountType } from "./SelectDiscountType";
import { SelectApplyUsers } from "./SelectApplyUsers";
import { SelectSKU } from "./SelectSKU";
import { DiscountConfig } from "./DisCountConfig";
import { Dayjs } from "dayjs";

export type EditForm = ProductDiscountForm & {
  applyBizType: string;
  applyProductCategory: string;
  applyProduct: string;
  range: any;
  applyProductsCopy: any[];
  pricePrecision: any;
};

export type EditData = {
  editData: EditForm;
  onChange: (data: EditForm) => void;
};

const defaultData: EditForm = {
  applyProducts: [],
  applyRange: "all",
  applyUsers: [],
  discountType: "discount_rate",
  status: true,
  applyBizType: "",
  applyProductCategory: "",
  applyProduct: "",
  applyProductsCopy: [],
  range: [],
  name: "",
  pricePrecision: 1,
};

export function AddDiscountModal({
  open,
  onCancel,
  productCategoryList,
  addDefaultData,
}: {
  open: boolean;
  onCancel: () => void;
  productCategoryList: Dayjs[];
  addDefaultData?: any;
}) {
  const [data, setData] = useState<EditForm>(defaultData);
  const [loading, setLoading] = useState(false);

  const [productList, setProductList] = useState<any[]>([]);

  const queryProduct = (
    businessType: string | undefined,
    productCategory: string | undefined
  ) => {
    const cleanParams = removeObjectEmptyValues({
      businessType,
      productCategory,
      pageSize: 100,
    });
    getProductSummary(cleanParams).then((res) => {
      if (Array.isArray(res.list)) {
        setProductList(res.list);
      }
    });
  };

  const addDiscount = useCallback(() => {
    if (!data.name) {
      message.error("请输入折扣名称");
      return;
    }
    if (!Array.isArray(data.range) || data.range.length !== 2) {
      message.error("请选择有效的时间范围");
      return;
    }
    if (
      !Array.isArray(data.applyProductsCopy) ||
      data.applyProductsCopy.length === 0
    ) {
      message.error("请选择适用产品");
      return;
    }
    const selectProduct = productList.find(
      (one) => one.productId === data.applyProductsCopy[0]
    );
    // if (!selectProduct ) {
    //   message.error("请选择适用产品");
    //   return;
    // }
    const isIncludeAll = data.applyProductsCopy.includes("all");
    const postData = {
      name: data.name,
      applyProducts: isIncludeAll
        ? [
            {
              applyBizType: data.applyBizType,
              applyProductCategory: data.applyProductCategory,
              applyProduct: "all",
            },
          ]
        : data.applyProductsCopy.map((id) => {
            const product = productList.find((one) => one.productId === id);
            return {
              applyBizType: data.applyBizType,
              applyProductCategory: data.applyProductCategory,
              applyProduct: product?.productId,
            };
          }),
      applyStartTime: data.range[0].unix(),
      applyEndTime: data.range[1].unix(),
      applyUsers: data.applyUsers,
      discountType: data.discountType,
      discountRate: data.discountRate ? Number(data.discountRate) : null,
      discountPrice0: realPriceFormat(
        data.discountPrice0,
        selectProduct?.pricePrecision
      ),
      discountPrice1: realPriceFormat(
        data.discountPrice1,
        selectProduct?.pricePrecision
      ),
      applyRange: data.applyRange,
    };
    const cleanData = removeObjectEmptyValues(postData);
    setLoading(true);
    createDiscount(cleanData)
      .then((res) => {
        console.log(res);
        message.success("创建成功");
        onCancel();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [data, productList, onCancel]);

  useEffect(() => {
    if (open) {
      queryProduct(data.applyBizType, data.applyProductCategory);
    }
  }, [data.applyBizType, data.applyProductCategory, open]);

  useEffect(() => {
    if (!open) {
      setData(defaultData);
    }
  }, [open]);

  useEffect(() => {
    if (addDefaultData) {
      setData((pre) => ({
        ...pre,
        ...addDefaultData,
        applyProductsCopy: [addDefaultData.applyProduct],
      }));
    }
  }, [addDefaultData]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={"新增折扣"}
      width={800}
      onOk={addDiscount}
      style={{
        top: 50,
      }}
      confirmLoading={loading}
    >
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣名称</div>
          <div className={styles.content}>
            <Input
              value={data.name}
              onChange={(e) => {
                setData({
                  ...data,
                  name: e.target.value,
                });
              }}
              placeholder="请输入折扣名称"
              style={{ width: 300 }}
            />
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣类型</div>
          <SelectDiscountType
            applyRange={data.applyRange}
            applyRangeChange={(value) => {
              setData({
                ...data,
                applyRange: value,
              });
            }}
          />
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>适用范围</div>
          <div className={styles.content}>
            {data.applyRange === "all" ? (
              <Input value="对全部用户生效" disabled style={{ width: 300 }} />
            ) : (
              <SelectApplyUsers
                applyUsers={data.applyUsers}
                onChange={(value) => {
                  setData({
                    ...data,
                    applyUsers: value,
                  });
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>产品信息</div>
          <div className={styles.content}>
            <SelectSKU
              editData={data}
              onChange={(data) => {
                setData(data);
              }}
              productCategoryList={productCategoryList}
              productList={productList}
            />
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣配置</div>
          <DiscountConfig
            editData={data}
            onChange={(data) => {
              setData(data);
            }}
            productList={productList}
          />
        </div>
      </div>
    </Modal>
  );
}
