import { Form, Input, message, Modal } from "antd";
import { ServerlessProductForm } from "../type";
import { useEffect, useState } from "react";
import {
  addServerlessProduct,
  editServerlessProduct,
} from "@/api/product";

export function EditModal({
  open,
  onClose,
  type,
  data,
}: {
  open: boolean;
  onClose: () => void;
  type: "add" | "edit";
  data: ServerlessProductForm;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const addProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      await addServerlessProduct({
        ...fields,
      });
      message.success("新增成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("新增失败");
    } finally {
      setLoading(false);
    }
  };

  const editProduct = async () => {
    const fields = form.getFieldsValue();
    try {
      setLoading(true);
      await editServerlessProduct({
        ...fields,
        id: data.id,
      });
      message.success("编辑成功");
      onClose();
    } catch (error) {
      console.error(error);
      message.error("编辑失败");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "edit") {
      form.setFieldsValue({
        ...data,
      });
    } else {
      form.resetFields();
    }
  }, [data, form, type]);

  const handleSubmit = () => {
    if (type === "add") {
      addProduct();
    } else {
      editProduct();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      title={type === "add" ? "新增产品" : "编辑产品"}
      onOk={handleSubmit}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item label="名称" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="GPU 名称" name="gpuName">
          <Input />
        </Form.Item>
        <Form.Item label="描述" name="models">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="GPU 大小" name="gpuSize">
          <Input />
        </Form.Item>
        <Form.Item label="Infra GPU 名称" name="infraGpuName">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
