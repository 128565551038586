import styles from "./style.module.css";
import { DatePicker, InputNumber, Radio } from "antd";
import dayjs from "dayjs";

export function EditDiscountConfig({
  formChange,
  product,
}: {
  formChange: (data: any) => void;
  product?: any;
}) {
  return (
    <div className={styles.content}>
      <div>
        <Radio.Group
          value={product.discountType}
          onChange={(e) => {
            formChange({
              discountType: e.target.value,
            });
          }}
        >
          <Radio value="discount_rate">按折扣系数</Radio>
          <Radio
            value="discount_price"
            disabled={product.applyProduct == "all"}
          >
            按折扣价
          </Radio>
        </Radio.Group>
      </div>
      {product.discountType === "discount_rate" && (
        <>
          <div className={styles.label2}>
            折扣系数 (折扣价 = 目录价 * 折扣系数)
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <InputNumber
                value={product.discountRate}
                onChange={(value) => {
                  formChange({
                    discountRate: value,
                  });
                }}
                controls={false}
                style={{ width: 100 }}
                min={1}
                max={100}
                step={1}
                precision={0}
                addonAfter="%"
              />
            </div>
          </div>
        </>
      )}
      {product.discountType === "discount_price" && (
        <>
          <div className={styles.label2}>折扣价</div>
          {product?.applyProductCategory !== "llm" ? (
            <InputNumber
              value={product.discountPrice0}
              onChange={(value) => {
                formChange({
                  discountPrice0: value,
                });
              }}
              controls={false}
              style={{ width: 300 }}
            />
          ) : (
            <>
              <div
                style={{
                  marginBottom: 10,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ width: 100, fontSize: 13 }}>input tokens</span>
                <InputNumber
                  value={product.discountPrice0}
                  onChange={(value) => {
                    formChange({
                      discountPrice0: value,
                    });
                  }}
                  controls={false}
                  style={{ width: 200 }}
                  placeholder="input token"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ width: 100, fontSize: 13 }}>output tokens</span>
                <InputNumber
                  value={product.discountPrice1}
                  onChange={(value) => {
                    formChange({
                      discountPrice1: value,
                    });
                  }}
                  controls={false}
                  style={{ width: 200 }}
                  placeholder="output token"
                />
              </div>
            </>
          )}
        </>
      )}

      {product.applyProduct !== "all" && (
        <>
          <div className={styles.label2}>产品价格预览</div>
          <div className={styles.table}>
            <table>
              <thead>
                <tr>
                  <td>产品</td>
                  <td>目录价</td>
                  <td>红线价</td>
                  <td>折扣价</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{product.displayName}</td>
                  <td>
                    {product?.applyProductCategory == "llm" ? (
                      <>
                        <span>{product?.basePrice0}</span>
                        <span> / </span>
                        <span>{product?.basePrice1}</span>
                      </>
                    ) : (
                      <span>{product?.basePrice0}</span>
                    )}
                  </td>
                  <td>
                    {product?.applyProductCategory == "llm" ? (
                      <>
                        <span>{product?.redlinePrice0}</span>
                        <span> /</span>
                        <span>{product?.redlinePrice1}</span>
                      </>
                    ) : (
                      product?.redlinePrice0
                    )}
                  </td>
                  <td>
                    {product?.applyProductCategory == "llm" ? (
                      <>
                        <span>{product?.discountPrice0}</span>
                        <span> /</span>
                        <span>{product?.discountPrice1}</span>
                      </>
                    ) : (
                      product?.discountPrice0
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}

      <div className={styles.label2}>生效时间</div>
      <DatePicker.RangePicker
        showTime
        value={product.range}
        onChange={(value) => {
          formChange({
            range: value,
          });
        }}
        presets={[
          {
            label: "7天",
            value: [dayjs(), dayjs().add(7, "days")],
          },
          {
            label: "1个月",
            value: [dayjs(), dayjs().add(1, "month")],
          },
          {
            label: "6个月",
            value: [dayjs(), dayjs().add(6, "month")],
          },
          {
            label: "1年",
            value: [dayjs(), dayjs().add(1, "year")],
          },
        ]}
      />
    </div>
  );
}
