import { PaginationComp } from "@/components/pagination";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  deleteServerlessProduct,
  queryServerlessProductList,
} from "@/api/product";
import styles from "./index.module.css";
import { EditModal } from "./EditModal";
import { removeObjectEmptyValues } from "@/utils";
import { TablePricingColumn } from "@/components/table-pricing";
import { DisDetailModal } from "@/components/table-pricing/DisDetailModal";
import { BusinessType } from "@/constants";
import { useNavigate } from "react-router-dom";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { hasDeleteProductResource, hasEditProductResource, hasSetDiscountResource, hasSetPriceResource } from "@/constants/auth";

export function ServerlessProcut() {
  const navigate = useNavigate();
  const { resource } = useSelector((state: RootState) => state.user);
  const [openDiscountDetail, setOpenDiscountDetail] = useState({
    open: false,
    productId: "",
  });
  const columns = [
    {
      title: "名称",
      dataIndex: "name",
    },
    {
      title: "GPU 名称",
      dataIndex: "gpuName",
    },
    {
      title: "描述",
      dataIndex: "models",
    },
    {
      title: "GPU 大小",
      dataIndex: "gpuSize",
    },
    {
      title: "Infra GPU 名称",
      dataIndex: "infraGpuName",
    },
    {
      title: "价格管理",
      dataIndex: "pricing",
      render: (value: any) => (
        <TablePricingColumn
          pricing={value}
          onClick={() =>
            setOpenDiscountDetail({ open: true, productId: value.productId })
          }
        />
      ),
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (_: unknown, record: any) => (
        <Space>
          {hasEditProductResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                setModalOpen({ type: "edit", open: true, data: record });
              }}
            >
              编辑
            </Button>
          )}
          {hasSetPriceResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/pricing/product?productId=${record.id}&businessType=${BusinessType.SERVERLESS}`
                );
              }}
            >
              设置价格
            </Button>
          )}
          {hasSetDiscountResource(resource) && (
            <Button
              type="link"
              onClick={() => {
                navigate(
                  `/pricing/discount?productId=${record.id}&productCategory=${record.productCategory}&businessType=${BusinessType.SERVERLESS}`
                );
              }}
            >
              设置折扣
            </Button>
          )}
          {hasDeleteProductResource(resource) && (
            <Button type="link" danger onClick={() => handleDelete(record.id)}>
              删除
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const [form] = Form.useForm();
  const [pageParams, setPageParams] = useState({
    pageIndex: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [modalOpen, setModalOpen] = useState<{
    type: "add" | "edit";
    open: boolean;
    data: any;
  }>({
    type: "add",
    open: false,
    data: {},
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const params = form.getFieldsValue();
      const cleanParams = removeObjectEmptyValues(params);
      const res = await queryServerlessProductList({
        ...pageParams,
        ...cleanParams,
      });
      if (Array.isArray(res.list)) {
        setDataSource(res.list);
        setTotal(res.total);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [pageParams, form]);

  const handleDelete = async (id: string) => {
    Modal.confirm({
      title: "确认删除",
      onOk: async () => {
        const res = await deleteServerlessProduct(id);
        if (res) {
          message.success("删除成功");
          fetchData();
        }
      },
    });
  };

  useEffect(() => {
    if (!modalOpen.open) {
      fetchData();
    }
  }, [fetchData, modalOpen.open]);

  return (
    <div>
      <div>
        <Breadcrumb
          items={[
            {
              title: "Serverless",
            },
            {
              title: "产品管理",
            },
          ]}
        />
      </div>
      <div className={styles.search_container}>
        <div className={styles.search_form}>
          <Form layout="inline" form={form}>
            <Form.Item label="名称" name="name">
              <Input />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    setPageParams({ pageIndex: 1, pageSize: 10 });
                  }}
                >
                  搜索
                </Button>
                <Button
                  onClick={() => {
                    form.resetFields();
                    fetchData();
                  }}
                >
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
          <div className={styles.action_container}>
            {hasEditProductResource(resource) && (
              <Button
                type="primary"
                onClick={() => {
                  setModalOpen({ type: "add", open: true, data: {} });
                }}
              >
                新增产品
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className={styles.table_container}>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
          rowKey="id"
        />
      </div>
      <div className={styles.pagination_container}>
        <PaginationComp
          total={total}
          pageNum={pageParams.pageIndex}
          pageSize={pageParams.pageSize}
          onChange={(pageNum, pageSize) => {
            setPageParams({ pageIndex: pageNum, pageSize });
          }}
        />
      </div>
      <EditModal
        open={modalOpen.open}
        type={modalOpen.type}
        data={modalOpen.data}
        onClose={() => {
          setModalOpen({ ...modalOpen, open: false });
        }}
      />
      <DisDetailModal
        open={openDiscountDetail.open}
        onClose={() =>
          setOpenDiscountDetail({ ...openDiscountDetail, open: false })
        }
        productId={openDiscountDetail.productId}
        businessType={BusinessType.SERVERLESS}
      />
    </div>
  );
}
