import { queryAccount } from "@/api/user";
import { Button, Input, Space, Tag } from "antd";
import { useEffect, useState } from "react";

export function SelectApplyUsers({
  applyUsers,
  onChange,
}: {
  applyUsers: string[];
  onChange: (value: string[]) => void;
}) {
  const [account, setAccount] = useState<string | undefined>(undefined);
  const [addUserInfo, setAddUserInfo] = useState<
    {
      email: string;
      phone: string;
      uuid: string;
    }[]
  >([]);
  const addUser = () => {
    if (account) {
      queryAccount(account).then((res) => {
        onChange([...applyUsers, res.uuid]);
        setAddUserInfo([...addUserInfo, { ...res }]);
        setAccount(undefined);
      });
    }
  };
  useEffect(() => {
    setAddUserInfo([]);
  }, []);
  return (
    <div>
      <Space>
        <Input value={account} onChange={(e) => setAccount(e.target.value)} />
        <Button onClick={addUser}>添加用户</Button>
      </Space>
      <div>
        {addUserInfo?.map((user) => (
          <Tag key={user.uuid}>{user.email || user.phone}</Tag>
        ))}
      </div>
    </div>
  );
}
