import { Input, message, Modal, Tag } from "antd";
import styles from "./style.module.css";
import { useCallback, useEffect, useState } from "react";
import { ProductDiscount, UpdateConfigForm } from "../types";
import { BusinessLineList } from "@/constants";
import { EditDiscountConfig } from "./EditDisConfig";
import dayjs, { Dayjs } from "dayjs";
import { displayPriceFormat, realPriceFormat } from "@/utils";
import { updateDiscount } from "@/api/pricing";

export function UpdateConfigModal({
  open,
  onCancel,
  defaultValue,
  productCategoryList,
}: {
  open: boolean;
  onCancel: () => void;
  defaultValue: any;
  productCategoryList: any[];
}) {
  const [data, setData] = useState<
    UpdateConfigForm &
      ProductDiscount & {
        range: [Dayjs, Dayjs];
        discountPrice0: number;
        discountPrice1: number;
        discountRate: number;
      }
  >(defaultValue ?? {});
  const [loading, setLoading] = useState(false);

  const BusinessType = BusinessLineList.find(
    (item) => item.value === data.applyBizType
  )?.label;

  const ProductCategory = productCategoryList.find(
    (item) => item.category === data.applyProductCategory
  )?.category;

  const editDiscount = useCallback(() => {
    if (!Array.isArray(data.range) || data.range.length !== 2) {
      message.error("请选择有效的时间范围");
      return;
    }
    setLoading(true);
    updateDiscount({
      ...data,
      discountRate: data.discountRate,
      discountPrice0: realPriceFormat(data.discountPrice0, data.pricePrecision),
      discountPrice1: realPriceFormat(data.discountPrice1, data.pricePrecision),
      basePrice0: realPriceFormat(data.basePrice0, data.pricePrecision),
      basePrice1: realPriceFormat(data.basePrice1, data.pricePrecision),
      applyStartTime: data.range[0].unix(),
      applyEndTime: data.range[1].unix(),
    })
      .then(() => {
        setLoading(false);
        onCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  }, [onCancel, data]);

  useEffect(() => {
    if (defaultValue) {
      console.log("defaultValue", defaultValue);
      setData({
        ...defaultValue,
        applyUsers:
          defaultValue?.applyUserInfo?.email ||
          defaultValue?.applyUserInfo?.phone,
        range: [
          dayjs(Number(defaultValue.applyStartTime) * 1000),
          dayjs(Number(defaultValue.applyEndTime) * 1000),
        ],
        basePrice0: displayPriceFormat(
          defaultValue.basePrice0,
          defaultValue.pricePrecision
        ),
        basePrice1: displayPriceFormat(
          defaultValue.basePrice1,
          defaultValue.pricePrecision
        ),
        redlinePrice0: displayPriceFormat(
          defaultValue.redlinePrice0,
          defaultValue.pricePrecision
        ),
        redlinePrice1: displayPriceFormat(
          defaultValue.redlinePrice1,
          defaultValue.pricePrecision
        ),
        discountPrice0: displayPriceFormat(
          defaultValue.discountPrice0,
          defaultValue.pricePrecision
        ),
        discountPrice1: displayPriceFormat(
          defaultValue.discountPrice1,
          defaultValue.pricePrecision
        ),
        discountRate: Number(defaultValue.discountRate),
      });
    }
  }, [defaultValue]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="编辑折扣配置"
      width={800}
      style={{
        top: 50,
      }}
      confirmLoading={loading}
      onOk={editDiscount}
    >
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣名称</div>
          <div className={styles.content}>
            <Input
              value={data.name}
              onChange={(e) => {
                setData({
                  ...data,
                  name: e.target.value,
                });
              }}
              placeholder="请输入折扣名称"
              style={{ width: 300 }}
            />
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣类型</div>
          <div>{data.applyRange === "all" ? "活动折扣" : "专属折扣"}</div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>适用范围</div>
          <div className={styles.content}>
            {data.applyRange === "all" ? (
              <div>对全部用户生效</div>
            ) : (
              <div>
                <Tag>{data.applyUsers}</Tag>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>产品信息</div>
          <div className={styles.content}>
            <div className={styles.label2}>业务分类</div>
            <div>{BusinessType}</div>
            <div className={styles.label2}>产品分类</div>
            <div>{ProductCategory}</div>
            <div className={styles.label2}>产品SKU</div>
            <div>
              <div>{data.applyProduct === "all" ? "全部" : data.displayName}</div>
              {Array.isArray(data.regionNames) && data.regionNames.length > 0 && (
                <div>
                  {data.regionNames.map((one) => (
                    <Tag key={one} color="blue">
                      {one}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.line}>
          <div className={styles.label}>折扣配置</div>
          <EditDiscountConfig
            formChange={(values) => {
              setData({
                ...data,
                ...values,
              });
            }}
            product={data}
          />
        </div>
      </div>
    </Modal>
  );
}
