import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import EditVoucherTemplate from "./editVoucherTemplate";
import {
  getVoucherTemplates,
  addEditVoucherTemplate,
  presentVoucher,
  confirmBanUser,
} from "@/api/beacon";
import dayjs from "dayjs";
import VoucherList from "./voucherList";
import PresendVoucher from "./presendVoucher";
import BanUserModal from "./banUserModal";
import { useLocation, useNavigate } from "react-router-dom";
import { voucherBusinessTypeList } from "@/constants";

function handleShowResource(key: string) {
  const resourceList = JSON.parse(
    localStorage.getItem("user_resource") || "{}"
  );
  return (
    resourceList?.resource?.includes(key) ||
    !!resourceList?.resource?.find((item: string) => item.startsWith(key))
  );
}

export function Voucher() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [modalInfo, setModalInfo] = useState({
    showModal: false,
    type: "Add",
    voucherTemplateInfo: {},
  });
  const [resultTable, setResultTable] = useState({ total: 0, data: [] });
  const [voucherModalInfo, setVoucherModalInfo] = useState<any>({
    showModal: false,
    templateId: "",
  });
  const [presendModalInfo, setPresendModalInfo] = useState<any>({
    showModal: false,
    templateId: "",
  });
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    id: "",
    name: "",
    amountMoney: "",
    availableStatus: true,
    businessType: "",
  });
  const [banModalInfo, setBanModalInfo] = useState<any>({
    showModal: false,
  });
  const columns: ColumnsType<any> = [
    {
      title: "代金券模板id",
      key: "id",
      dataIndex: "id",
    },
    {
      title: "代金券名称",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "代金券时效",
      key: "ageing",
      dataIndex: "ageing",
      render: (_: any, record: any) => {
        let timeEffect = "";
        let content = "";
        if (record.ageing === "fixed") {
          timeEffect = "固定时段";
          const startDate =
            record.startDate !== "" && record.startDate !== "0"
              ? dayjs.unix(record.startDate).format("YYYY-MM-DD HH:mm:ss") || ""
              : "";
          const endDate =
            record.endDate !== "" && record.endDate !== "0"
              ? dayjs.unix(record.endDate).format("YYYY-MM-DD HH:mm:ss") || ""
              : "";
          content = startDate + "---" + endDate;
        } else if (record.ageing === "dynamic") {
          timeEffect = "动态时段";
          content = record.days + "天";
        }
        return (
          <div>
            <div>{timeEffect}</div>
            <div>{content}</div>
          </div>
        );
      },
    },
    {
      title: "代金券金额",
      key: "amountMoney",
      dataIndex: "amountMoney",
      render: (_: any, record: any) => {
        return (
          <div>
            {record.amountMoney
              ? Number(record.amountMoney) / 10000
              : record.amountMoney}
          </div>
        );
      },
    },
    {
      title: "业务类型",
      key: "businessTypes",
      dataIndex: "businessTypes",
      render: (_: any, record: any) => {
        return (
          <div>
            {record.businessTypes ? record.businessTypes.join(",") : ""}
          </div>
        );
      },
    },
    {
      title: "可用状态",
      key: "availableStatus",
      dataIndex: "availableStatus",
      render: (_: any, record: any) => {
        return <div>{record.availableStatus ? "可用" : "不可用"}</div>;
      },
    },
    {
      title: "操作",
      render: (_: any, record: any) => {
        return (
          <div>
            {handleShowResource("beacon-voucher-present") && (
              <Button
                style={{ paddingLeft: "0" }}
                type="link"
                onClick={() =>
                  setPresendModalInfo({
                    showModal: true,
                    templateId: record.id,
                  })
                }
              >
                发放
              </Button>
            )}
            {handleShowResource("beacon-voucher-edit") && (
              <Button type="link" onClick={() => addVoucherTemplate(record)}>
                修改
              </Button>
            )}
            {handleShowResource("beacon-voucher-present-list") && (
              <Button
                type="link"
                onClick={() =>
                  setVoucherModalInfo({
                    showModal: true,
                    templateId: record.id,
                  })
                }
              >
                发放记录
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  function searchData() {
    getTableData(1);
  }

  function getTableData(pageNo?: any, pageSize?: any) {
    getVoucherTemplates({
      ...params,
      amountMoney: params.amountMoney
        ? (Number(params.amountMoney) * 10000).toString()
        : params.amountMoney,
      pageNo: !isNaN(Number(pageNo)) ? pageNo : params.pageNo,
      pageSize: !isNaN(Number(pageSize)) ? pageSize : params.pageSize,
    }).then((res: any) => {
      console.log(res);
      setResultTable({ data: res.data || [], total: Number(res.total) });
      setParams({
        ...params,
        pageNo: isNaN(Number(pageNo)) ? params.pageNo : pageNo,
        pageSize: isNaN(Number(pageSize)) ? params.pageSize : pageSize,
      });
    });
  }
  function changePagination(page: any, pageSize: any) {
    if (pageSize !== params.pageSize) {
      // setParams({...params, pageNo: 1, pageSize, total: 0})
      getTableData(1, pageSize);
    } else {
      // setParams({...params, pageNo: page, pageSize, total: 0})
      getTableData(page, pageSize);
    }
  }
  useEffect(() => {
    if (!handleShowResource(pathname)) {
      navigate("/dashboard");
      return;
    }
    getTableData();
  }, []);

  function addVoucherTemplate(voucherTemplateInfo: any) {
    if (!voucherTemplateInfo) {
      setModalInfo({ showModal: true, type: "Add", voucherTemplateInfo: {} });
    } else {
      setModalInfo({
        showModal: true,
        type: "Edit",
        voucherTemplateInfo: {
          ...voucherTemplateInfo,
          amountMoney: voucherTemplateInfo.amountMoney
            ? Number(voucherTemplateInfo.amountMoney) / 10000
            : voucherTemplateInfo.amountMoney,
          startDate:
            voucherTemplateInfo.startDate === "0"
              ? ""
              : voucherTemplateInfo.startDate,
          endDate:
            voucherTemplateInfo.endDate === "0"
              ? ""
              : voucherTemplateInfo.endDate,
        },
      });
    }
  }
  const [editVoucherTemplateBtnLoading, setEditVoucherTemplateBtnLoading] =
    useState(false);
  function finishOperate(
    cancelMark: boolean,
    type: string,
    voucherTemplateInfo: any
  ) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("voucherTemplateInfo:", voucherTemplateInfo);
      setModalInfo({ ...modalInfo, showModal: false });
    } else {
      // debugger
      console.log("cancelMark:", cancelMark);
      console.log("type:", type);
      console.log("voucherTemplateInfo:", voucherTemplateInfo);
      if (
        !voucherTemplateInfo.businessTypes ||
        voucherTemplateInfo.businessTypes.length <= 0
      ) {
        message.error("请选择业务类型");
        return;
      }
      setEditVoucherTemplateBtnLoading(true);
      addEditVoucherTemplate({
        id: voucherTemplateInfo.id,
        name: voucherTemplateInfo.name,
        ageing: voucherTemplateInfo.ageing,
        startDate:
          voucherTemplateInfo.ageing === "fixed"
            ? dayjs(
                voucherTemplateInfo.ageingDate[0].format("YYYY-MM-DD") +
                  " 00:00:00"
              )
                .unix()
                .toString()
            : "0",
        endDate:
          voucherTemplateInfo.ageing === "fixed"
            ? dayjs(
                voucherTemplateInfo.ageingDate[1].format("YYYY-MM-DD") +
                  " 23:59:59"
              )
                .unix()
                .toString()
            : "0",
        days:
          voucherTemplateInfo.ageing === "dynamic"
            ? Number(voucherTemplateInfo.days)
            : 0,
        amountMoney: Number(voucherTemplateInfo.amountMoney) * 10000 + "",
        availableStatus: voucherTemplateInfo.availableStatus,
        businessTypes: voucherTemplateInfo.businessTypes,
      })
        .then(() => {
          message.success("操作成功");
          setModalInfo({ ...modalInfo, showModal: false });
          getTableData();
        })
        .finally(() => {
          setEditVoucherTemplateBtnLoading(false);
        });
    }
  }
  const [presendVoucherBtnLoading, setPresendVoucherBtnLoading] =
    useState(false);
  function finishPresend(cancelMark: boolean, customerInfo: any) {
    if (!cancelMark) {
      console.log("cancelMark:", cancelMark);
      console.log("customerInfo:", customerInfo);
      setPresendModalInfo({ showModal: false, templateId: "" });
    } else {
      // debugger
      console.log("cancelMark:", cancelMark);
      console.log("customerInfo:", customerInfo);
      setPresendVoucherBtnLoading(true);
      presentVoucher({
        templateId: presendModalInfo.templateId,
        email: customerInfo.email,
      })
        .then(() => {
          message.success("操作成功");
          setPresendModalInfo({ showModal: false, templateId: "" });
          getTableData();
        })
        .finally(() => {
          setPresendVoucherBtnLoading(false);
        });
    }
  }
  const [banUserModalBtnLoading, setBanUserModalBtnLoading] = useState(false);
  function banUser(cancelMark: boolean, customerInfo: any) {
    if (!cancelMark) {
      setBanModalInfo({ showModal: false });
    } else {
      setBanUserModalBtnLoading(true);
      confirmBanUser({
        userId: customerInfo.userId,
      })
        .then(() => {
          message.success("操作成功");
          setBanModalInfo({ showModal: false });
        })
        .finally(() => {
          setBanUserModalBtnLoading(false);
        });
    }
  }
  function inputSetParamsText(item: string, e: any, valueType = "") {
    const paramsTmp: any = params;
    paramsTmp[item] = valueType === "value" ? e : e.target.value;
    setParams({ ...paramsTmp });
  }
  return (
    <div style={{ padding: "10px" }}>
      <div>
        {/* <span style={{ marginBottom: "20rem", fontSize: "16px", fontWeight: "bolder" }}>
        代金券模板管理
      </span> */}
        <div>
          <div>
            <Form>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="代金券模板id：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("id", e)}
                    style={{ width: "120px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="代金券名称：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("name", e)}
                    style={{ width: "200px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="业务类型">
                  <Select
                    style={{ width: "140px" }}
                    options={voucherBusinessTypeList}
                    onChange={(e: any) =>
                      inputSetParamsText("businessType", e, "value")
                    }
                  ></Select>
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="代金券金额：">
                  <Input
                    onChange={(e: any) => inputSetParamsText("amountMoney", e)}
                    style={{ width: "120px" }}
                  />
                </Form.Item>
              </div>
              <div style={{ display: "inline-block", marginRight: "20px" }}>
                <Form.Item label="可用状态：">
                  <Checkbox
                    defaultChecked={true}
                    onChange={(e: any) =>
                      inputSetParamsText(
                        "availableStatus",
                        e.target.checked,
                        "value"
                      )
                    }
                  >
                    可用
                  </Checkbox>
                </Form.Item>
              </div>
              <Button
                style={{ marginRight: "20px" }}
                onClick={searchData}
                type="primary"
              >
                查询
              </Button>
              {handleShowResource("beacon-voucher-add") && (
                <Button type="primary" onClick={() => addVoucherTemplate(null)}>
                  新增
                </Button>
              )}
              <Button
                style={{ marginLeft: "20px", color: "red" }}
                onClick={() => setBanModalInfo({ showModal: true })}
              >
                封号
              </Button>
            </Form>
          </div>
          <Table
            columns={columns}
            dataSource={resultTable.data}
            pagination={false}
          ></Table>
          {resultTable.total > 0 ? (
            <Pagination
              style={{
                marginTop: "10px",
                marginBottom: "10px",
                float: "right",
              }}
              pageSize={Number(params.pageSize)}
              current={Number(params.pageNo)}
              total={resultTable.total}
              showSizeChanger
              showQuickJumper
              onChange={(page: number, pageSize: number) =>
                changePagination(page, pageSize)
              }
              showTotal={(total) => `共 ${total} 条`}
            />
          ) : (
            ""
          )}
          {/* <Pagination total={85} showSizeChanger showQuickJumper showTotal={total => `Total ${total} items`} /> */}
        </div>
      </div>
      {modalInfo.showModal ? (
        <EditVoucherTemplate
          btnLoading={editVoucherTemplateBtnLoading}
          finishOperate={finishOperate}
          showModal={modalInfo.showModal}
          type={modalInfo.type}
          voucherTemplateInfo={modalInfo.voucherTemplateInfo}
        />
      ) : (
        ""
      )}
      {voucherModalInfo.showModal ? (
        <Modal
          title="代金券明细"
          width={800}
          open={voucherModalInfo.showModal}
          onCancel={() =>
            setVoucherModalInfo({ showModal: false, templateId: null })
          }
          footer={null}
        >
          <VoucherList templateId={voucherModalInfo.templateId} />
        </Modal>
      ) : (
        ""
      )}
      {presendModalInfo.showModal ? (
        <PresendVoucher
          btnLoading={presendVoucherBtnLoading}
          showModal={presendModalInfo.showModal}
          templateId={presendModalInfo.templateId}
          finishPresend={finishPresend}
        />
      ) : (
        ""
      )}
      {banModalInfo.showModal ? (
        <BanUserModal
          btnLoading={banUserModalBtnLoading}
          showModal={banModalInfo.showModal}
          finishPresend={banUser}
        />
      ) : (
        ""
      )}
    </div>
  );
}
